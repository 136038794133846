import React from 'react';
import CookieConsent from "react-cookie-consent";

const HeaderSection = ({ children }) => (
<div className="flex flex-row mt-12 md:mt-24 mb-24 md:mb-32">
    <div className="flex flex-col md:w-full">
      {children}
    </div>
  <CookieConsent buttonText="Kein Thema" buttonStyle={{background: "#1098ad", color: "black"}}>
    Diese Seite verwendet Cookies für ein verbessertes Nutzungserlebnis. Durch die weitere Nutzung erklärst du dich damit einverstanden.
  </CookieConsent>
  </div>
);

export default HeaderSection;
